.titleHeader {
  width: 25%;
}

.emptyHeader {
  width: 5%;
}

.numberHeader {
  width: 10%;
}

.publisherHeader {
  width: 20%;
}

.topButtonPanel {
  border-style: solid;
  border-width: thin;
  border-color: black;
  border-left: none;
  border-right: none;
  float: left;
  width: 100%;
}

.bottomButtonPanel {
  border-style: solid;
  border-width: thin;
  border-color: black;
  border-left: none;
  border-right: none;
}
