.mainDiv {
  background-color: black;
  width: 100%;
  height: 100vh;
}

.mainImg {
  width: 480px;
}

.inputs {
  margin: auto;
  font: inherit;
  width: 30%;
  border: 0;
  height: 1.1876em;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="mainDiv"] {
    background-color: black;
    height: 100vh;
  }

  [class*="mainImg"] {
    width: 80%;
  }
}
