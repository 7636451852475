.scrollableModal {
  overflow-y: scroll;
  max-height: 90vh;
}

.modal-90w {
  width: 90vw;
  max-width: 90vw;
}

.container-50w-left {
  border-style: solid;
  border-width: thick;
  border-color: red;
  width: 50%;
  float: left;
}

.container-50w-right {
  border-style: solid;
  border-width: thick;
  border-color: red;
  width: 50%;
  float: right;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 770;
  width: 500;
}

.button-div {
  width: 100%;
  float: left;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="container-50w-right"] {
    border-style: solid;
    border-width: thick;
    border-color: red;
    width: 100%;
    float: none;
  }

  [class*="container-50w-left"] {
    border-style: solid;
    border-width: thick;
    border-color: red;
    width: 100%;
    float: none;
  }

  [class*="img-center"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 425px;
    width: 300px;
  }
}